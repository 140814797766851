const palette = {
    button1: '#7F5539',
    button2: '#63422C',
    main1: '#B08F79',
    main2: '#DDB892',
    font: '#282828',
    grey: '#D5D1CD',
    white: '#FFFCFA',
};

export default palette;
